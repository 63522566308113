import React from 'react';
import { Box } from '@mui/material';

const Filter = ({ getJobs, searchParams, setSearchParams, data, title, field }: { 
  getJobs: () => void,
  searchParams: URLSearchParams, 
  setSearchParams: (x: URLSearchParams) => void,
  data: { title: string, filter: string}[],
  title: string,
  field: string
}) => {

  const remove = () => {
    searchParams.delete(field);
    setSearchParams(searchParams);
    getJobs()
  }

  const search = (filter: string) =>  {
    searchParams.set(field, filter);
    setSearchParams(searchParams)
    getJobs()
  }

  return (
    <Box>
      <p style={{ marginTop: 10, fontSize: 17, fontWeight: "bold" }}>{title}</p>
      <ul style={{ listStyle: "none", paddingLeft: 10, }}>
        {
          data.map(datum => {
            return (
              <li key={datum.filter} style={{ padding: 2, fontSize: 13,}}>
                <span style={{ cursor: "pointer", }} onClick={() => { 
                  search(datum.filter);
                }}>{datum.title}</span> 
                <span 
                  style={{ 
                    color: "grey", 
                    marginLeft: 5, 
                    marginTop: -1, 
                    position: "absolute", 
                    cursor: "pointer", 
                    display: searchParams.get(field)?.toString() === datum.filter ? "inline-block" : "none" 
                  }} 
                  onClick={remove}>[ x ]</span>
              </li>
            )
          })
        }

      </ul>
    </Box>
  );
}


export default Filter;
