export const CITIES = [
  { name: "", filter: false },
  { name: "UK", filter: false },
  { name: "England", filter: true },
  { name: "Scotland", filter: true },
  { name: "Wales", filter: true },
  { name: "Aberdeen", filter: false },
  { name: "Armagh", filter: false },
  { name: "Bangor", filter: false },
  { name: "Bath", filter: false },
  { name: "Belfast", filter: false },
  { name: "Birmingham", filter: false },
  { name: "Bradford", filter: false },
  { name: "Brighton and Hove", filter: false },
  { name: "Bristol", filter: false },
  { name: "Cambridge", filter: false },
  { name: "Canterbury", filter: false },
  { name: "Cardiff", filter: false },
  { name: "Carlisle", filter: false },
  { name: "Chester", filter: false },
  { name: "Chichester", filter: false },
  { name: "Coventry", filter: false },
  { name: "Derby", filter: false },
  { name: "Dundee", filter: false },
  { name: "Durham", filter: false },
  { name: "Edinburgh", filter: false },
  { name: "Ely", filter: false },
  { name: "Exeter", filter: false },
  { name: "Glasgow", filter: false },
  { name: "Gloucester", filter: false },
  { name: "Hereford", filter: false },
  { name: "Inverness", filter: false },
  { name: "Kingston upon Hull", filter: false },
  { name: "Lancaster", filter: false },
  { name: "Leeds", filter: false },
  { name: "Leicester", filter: false },
  { name: "Lichfield", filter: false },
  { name: "Lincoln", filter: false },
  { name: "Lisburn", filter: false },
  { name: "Liverpool", filter: false },
  { name: "London", filter: false },
  { name: "Londonderry", filter: false },
  { name: "Manchester", filter: false },
  { name: "Newcastle upon Tyne", filter: false },
  { name: "Newport", filter: false },
  { name: "Newry", filter: false },
  { name: "Norwich", filter: false },
  { name: "Nottingham", filter: false },
  { name: "Oxford", filter: false },
  { name: "Peterborough", filter: false },
  { name: "Plymouth", filter: false },
  { name: "Portsmouth", filter: false },
  { name: "Preston", filter: false },
  { name: "Ripon", filter: false },
  { name: "Salford", filter: false },
  { name: "Salisbury", filter: false },
  { name: "Sheffield", filter: false },
  { name: "Southampton", filter: false },
  { name: "St Albans", filter: false },
  { name: "St Davids", filter: false },
  { name: "Stirling", filter: false },
  { name: "Stoke-on-Trent", filter: false },
  { name: "Sunderland", filter: false },
  { name: "Swansea", filter: false },
  { name: "Truro", filter: false },
  { name: "Wakefield", filter: false },
  { name: "Wells", filter: false },
  { name: "Westminster", filter: false },
  { name: "Winchester", filter: false },
  { name: "Wolverhampton", filter: false },
  { name: "Worcester", filter: false },
  { name: "York", filter: false },
]