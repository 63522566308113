import React, { useEffect, useState } from 'react';
import './App.css';
import { Alert, Autocomplete, Box, Button, Container, Grid, Pagination, Stack, TextField } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { CITIES } from './cities';
import JobCard from './components/JobCard';
import Filter from './components/Filter';
import { ContractTypes, WorkingHours } from './Filters';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';

type data = { jobs: any[], location: string[], company: string[], pages: number, hits: number };


function App() {

  const [searchParams, setSearchParams] = useSearchParams();
  const [jobs, setJobs] = useState<data>({
    jobs: [],
    location: [],
    company: [],
    pages: 1,
    hits: 0,
  });

  const [location, setLocation] = useState<string>(searchParams.get("location") || "")

  const getJobs = async () => {
    let url = `https://hgvdriversjobs.com/api/?${searchParams.toString()}`
    const response = await fetch(url, {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        "Content-Type": "application/json"
      }
    });
    const jobs = await response.json();
    setJobs(jobs)
  }

  useEffect(() => {
    getJobs()
  }, [])


  return (
    <Container maxWidth="lg">
      <header className="App-header" style={{ marginBottom: 5, borderBottom: "1px solid #80808052", display: "flex" }}>
        <h2 style={{ color: "red", marginRight: 20} }>HGVDriversJobs.com</h2>
        <p style={{ marginRight: 0, marginLeft: "auto", marginTop: 30, fontSize: 15 }}>Dedicated job search for HGV drivers in the UK</p>
      </header>
    
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <Stack spacing={2}>
            <Autocomplete
              style={{ marginTop: 40, marginBottom: 20 }}
              onChange={(x, location) => {
                searchParams.set("location", location);
                setSearchParams(searchParams);
                setLocation(location);
                getJobs();
              }}
              value={location}
              onClose={(e) => {
                if(e.currentTarget.textContent === "") {
                  searchParams.delete("location");
                  setSearchParams(searchParams);
                  setLocation("")
                  getJobs()
                }
              }}

              freeSolo
              disableClearable
              options={CITIES.map((option) => option.name)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size='small'
                  placeholder='Location..'
                  fullWidth
                  InputProps={{
                    ...params.InputProps,
                    type: 'search',
                  }}
                />
              )}
            />
            <Filter
              title={"Contract type"}
              getJobs={getJobs} 
              searchParams={searchParams} 
              setSearchParams={setSearchParams}
              data={ContractTypes}
              field={"type"}
            />
            <Filter 
              title={"Working hours"}
              getJobs={getJobs} 
              searchParams={searchParams} 
              setSearchParams={setSearchParams}
              data={WorkingHours}
              field={"period"}
            />
          </Stack>
        </Grid>

        <Grid item xs={9}>
          <p style={{ float: "right", fontSize: 14, display: jobs.hits === 0 ? "none" : "flex" }}>Found {jobs.hits} jobs</p>
          <Stack spacing={2}>    
            { 
              jobs?.jobs.map((job, index) => <JobCard job={job} key={index}/>)
            }

            <Box style={{ marginBottom: 25, justifyContent: "center", display: jobs.hits === 0 ? "none" : "flex" }}>
              <Pagination page={searchParams.get("page") ? Number(searchParams.get("page")) : 1} count={jobs?.pages} variant="outlined" onChange={(e, page) => {
                searchParams.set("page", page.toString());
                setSearchParams(searchParams);
              }}/>
            </Box>
            
            <Box style={{ marginTop: 25, justifyContent: "center", display: jobs.hits === 0 ? "flex" : "none", width: "100%" }}>
              <p>Sorry, no jobs found...</p>
            </Box>

          </Stack>

        </Grid>
      </Grid>
    </Container>
  );
}

export default App;
