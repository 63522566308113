export const ContractTypes = [
  { 
    title: "Permanent", 
    filter: "p"
  },
  { 
    title: "Contract", 
    filter: "c"
  },
  { 
    title: "Temporary", 
    filter: "t"
  },
  { 
    title: "Training", 
    filter: "i"
  },
];

export const WorkingHours = [
  { 
    title: "Full-time", 
    filter: "f"
  },
  { 
    title: "Part-time", 
    filter: "p"
  }
]