import React from 'react';
import { Box, Icon } from '@mui/material';
import CurrencyPoundIcon from '@mui/icons-material/CurrencyPound';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';



const JobCard = ({ job }: { job: any }) => {
  
  return (
    <a href={job.url}>
      <Box 
        component="section" 
        sx={{ p: 2, cursor: "pointer", borderBottom: '1px solid #80808052', borderRadius: 1, fontSize: 14, }} 
      >

        <Box sx={{}}>
          <p style={{ fontSize: 20, fontWeight: "bold" }}>{job.title}</p>
          <p style={{ fontWeight: 500 }}>{job.company}</p>
          <p style={{ marginBottom: 0 }}>{(new Date(job.date)).toLocaleDateString()}</p>
        </Box>

        <Box sx={{ display: "flex" }}>
          <div style={{ display: "flex"}}>
            <CurrencyPoundIcon fontSize='small' style={{ marginTop: 15, marginRight: 5, fontSize: 14 }}/>
            <p style={{ marginRight: 45, width: 200 }}>{job.salary.replace("&pound;", "£") || "Negotiable"}</p>
          </div>

          <div style={{ display: "flex"}}>
            <LocalShippingIcon fontSize='small' style={{ marginTop: 15, marginRight: 5, fontSize: 16 }}/>
            <p style={{ marginRight: 45, width: 200 }}>{job.locations}</p>
          </div>

        </Box>
        <Box sx={{ display: "flex" }}>
          {job.description.replace(/(<([^>]+)>)/ig, '')}
        </Box>
        
      </Box>
    </a>


  );
}

export default JobCard;
